/** @jsx jsx */
import { jsx, useThemeUI } from "theme-ui";
import { getColor } from "@theme-ui/color";
import { StaticImage } from "gatsby-plugin-image";

import { styled } from "style";

import Section from "components/SectionWrapper";
import BlockContent from "components/BlockContent";
import DefaultImage from "components/Image";

const defaultProps = {
  variant: "default",
};

const Wrapper = styled(Section)({
  mt: [0, 0, 4.5],
  backgroundColor: "lime.100",
  color: "lime.900",
});

const Container = styled("div")({
  position: "relative",
  display: "grid",
  gridTemplateColumns: ["1fr", null, "repeat(2, minmax(0, 1fr))"],
  gridTemplateRows: "auto",
  gridTemplateAreas: ["'image' 'text'", null, "'image text'"],
  columnGap: 4,
  alignItems: "center",
  width: "100%",
  maxWidth: "xl",
  mx: "auto",
  px: 3,
});

const Image = styled(DefaultImage, {
  forwardProps: ["asset", "backgroundColor", "loading", "width"],
})({
  gridArea: "image",
  position: "relative",
  maxWidth: ["sm", null, "none"],
  px: 3,
  mt: [0, 0, -4.5],
  mb: [0, 0, 4.5],
  borderRadius: "sm",
  boxShadow: "lg",
});

const Text = styled(BlockContent, {
  forwardProps: ["blocks"],
})({
  gridArea: "text",
  py: 5,
  "& > *": {
    mx: [3, 4],
    maxWidth: "sm",
  },
});

const Hero = ({ image, blocks, variant, ...props }) => {
  const { theme } = useThemeUI();

  return (
    <Wrapper {...props}>
      <StaticImage
        alt=""
        formats={["auto", "webp", "avif"]}
        placeholder="none"
        layout="fullWidth"
        role="presentation"
        src="../assets/images/hero-marker-background.jpg"
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          borderRadius: "inherit",
          userSelect: "none",
          pointerEvents: "none",
        }}
      />
      <StaticImage
        alt="an illustrated flower and bird"
        formats={["auto", "webp", "avif"]}
        placeholder="none"
        src="../assets/images/sketch-bird-flower.png"
        style={{
          position: "absolute",
          bottom: -45,
          right: "20%",
          userSelect: "none",
          pointerEvents: "none",
        }}
        width={240}
      />
      <Container>
        {image && (
          <Image
            loading="eager"
            width={544}
            backgroundColor={getColor(theme, "lime.100")}
            {...image.image}
          />
        )}
        {blocks && <Text blocks={blocks} />}
      </Container>
    </Wrapper>
  );
};

Hero.defaultProps = defaultProps;

export default Hero;
